<template>
  <div class="business-order">
    <div class="text-center">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false">
      </loading>
    </div>
    <div v-if="!isLoading">
      <div v-if="showWizard">
        <h2>{{ pageTitle }}</h2>
        <div class="progress-step">
          <div class="progress-percent" :style="{ width: currentStep / totalSteps * 100 + '%' }">
            Step {{ currentStep }} of {{ totalSteps }}...
          </div>
        </div>
        <ValidationObserver v-if="currentStep === 1" v-slot="{ handleSubmit, invalid }" ref="formStep1">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="row step-container">
              <div class="col-md-12">
                <text-input validate="required" name="Full Name" v-model="credentials.full_name"
                  :label="'auth.businessOrder.full_name' | translate"/>
              </div>
              <div class="col-md-12">
                <text-input validate="required" name="Company Name" v-model="credentials.company_name"
                  :label="'auth.businessOrder.company_name' | translate"/>
              </div>
              <div class="col-md-12">
                <text-input validate="required" :minlength="8" type="password" name="Password" v-model="credentials.password"
                  :label="'auth.businessOrder.password' | translate" vid="password"/>
              </div>
              <div class="col-md-12">
                <text-input validate="required|confirmed:password" :minlength="8" type="password" name="Password Confirmation" v-model="credentials.password_confirmation"
                  :label="'auth.businessOrder.confirm_password' | translate"/>
              </div>
              <div v-show="showEmail" class="col-md-12">
                <text-input validate="required|email" name="email" v-model="credentials.email"
                  :label="'auth.businessOrder.email' | translate"/>
              </div>
              <div class="col-md-12">
                <SelectInput :label="'auth.businessOrder.industry' | translate" v-model="credentials.industry" name="industry"
                  :options="industriesOptions" />
              </div>
            </div>
          </form>
        </ValidationObserver>
        <template v-if="currentStep === 2">
        <div v-if="plan">
          <div v-if="!isImmeidateCharge" class="no-payment-text text-center">
            <h6 class="font-weight-bold">No Payment Today</h6>
            <p>Your card will not be charged until your trial is over. <br />You can cancel at any time prior to the
              charge with no payment.</p>
          </div>
          <AddCreditCard
            :addPaymentMethod="false"
            :eventBus="eventbus"
            :plan="plan"
            :skipTrial="forceNoTrial"
            v-on:done="handleVerifiedCard"
          />
        </div>
        <ValidationObserver v-else v-slot="{ handleSubmit, invalid }" ref="formStep2">
          <form @submit.prevent="handleSubmit(submit)">
            <div class="row step-container">
              <div class="col-md-12">
                <SelectInput :label="'auth.businessOrder.total_contacts' | translate" v-model="credentials.total_contacts" name="Total Contacts" :options="contactOptions" />
              </div>
              <div class="col-md-12">
                <div class="row mt-2 mb-4">
                  <div class="col">
                    <label class="lelabel">{{ 'auth.businessOrder.departments' | translate }}</label>
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input type="checkbox" name="departments" id="depSales" value="1" v-model="credentials.departments">
                      <label for="depSales">
                        <span class="abc-label-text">Sales</span>
                      </label>
                    </div>
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input type="checkbox" name="departments" id="devSupport" value="2" v-model="credentials.departments">
                      <label for="devSupport">
                        <span class="abc-label-text">Support</span>
                      </label>
                    </div>
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input type="checkbox" name="departments" id="depMarketing" value="3" v-model="credentials.departments">
                      <label for="depMarketing">
                        <span class="abc-label-text">Marketing</span>
                      </label>
                    </div>
                    <div class="checkbox abc-checkbox abc-checkbox-primary">
                      <input type="checkbox" name="departments" id="depOther" value="4" v-model="credentials.departments">
                      <label for="depOther">
                        <span class="abc-label-text">Other</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <text-input name="Total People" type="number" validate="required" v-model="credentials.total_people" :label="'auth.businessOrder.total_people' | translate"/>
              </div>
            </div>
          </form>
        </ValidationObserver>
        </template>
        <div class="d-block">
          <div class="d-block">
            <Button type="button" :classes="`w-100 btn btn-primary btn-next ${nextButtonClass}`" :loading="processing" @click="handleNext()">
              {{ nextButtonLabel }}
            </Button>
          </div>
          <a v-if="showPrevious" href="javascript:;" class="mt-3 d-block prev-link" @click="handlePrev()"><i class="fa fa-arrow-left"></i> {{'auth.businessOrder.prev' | translate}}</a>
        </div>
      </div>

      <div v-else>
        <div class="col-md-12">
          <h3 class="text-center font-weight-bold">
            {{ 'auth.businessOrder.step3_title' | translate }}
          </h3>
          <p>
            {{ 'auth.businessOrder.step3_p1' | translate }}
          </p>
          <p>
            {{ 'auth.businessOrder.step3_p2' | translate }}
          </p>
          <p>
            {{ 'auth.businessOrder.step3_p3' | translate }}
          </p>
          <div v-if="calendlyUrl" class="d-block w-100" style="margin-top: -40px">
            <div class="calendly-inline-widget" :data-url="calendlyUrl" style="min-width:320px;height:700px;"></div>
          </div>

          <div class="skip-wrapper mt-3">
            <a href="javascript:;" @click="onCalendarSkip">{{ 'auth.businessOrder.skip' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCreditCard from '@/components/order/AddCreditCardBusiness.vue'
import Loading from 'vue-loading-overlay'

export default {
  components: {
    AddCreditCard,
    Loading
  },

  data () {
    return {
      isLoading: true, 
      credentials: {
        email: '',
        company_name: '',
        full_name: '',
        password: '',
        departments: [],
        total_people: 1,
        payment_method: undefined,
        coupon: undefined,
        plan: undefined
      },
      currentStep: 1,
      plan: null,
      contactOptions: [
        { value: 1, label: '0 - 500' },
        { value: 2, label: '501 - 2,000' },
        { value: 3, label: '2,001 - 5,000' },
        { value: 4, label: '5,001 - 20,000' },
        { value: 5, label: '20,000 - 100,000' },
        { value: 6, label: '100,001 +' },
      ],
      processing: false,
      totalSteps: 2,
      showWizard: true, 
      eventbus: new Vue(),
      showEmail: true,
      forceNoTrial: false,
    }
  },

  mounted () {
    this.forceNoTrial = this.$route.query.t === 'n'
    if (this.$route.query.email)
    {
      this.showEmail = false
      this.credentials.email = this.$route.query.email;
    }

    if (this.$route.query.name)
    {
      this.credentials.full_name = this.$route.query.name;
    }

    if (this.$route.query.plan) {
      this.getPlan()
    } else {
      this.isLoading = false;
    }
  },

  methods: {
    submit () {
    },

    addCalendarScript () {
      if (this.calendlyUrl)
      {
        let script = document.createElement('script')
        script.src = "https://assets.calendly.com/assets/external/widget.js"
        script.async = "true"

        document.body.append(script)
      }
    },

    handleVerifiedCard (arg) {
      const { id, coupon } = arg
      this.credentials.payment_method = id
      this.credentials.coupon = coupon ? coupon.id: undefined
      this.processing = false
      if (this.credentials.payment_method)
      {
        this.register();
      }
    },

    handlePrev() {
      this.currentStep --
    },

    async handleNext () {
      let valid = false;
      this.processing = true

      if (this.currentStep == 1) {
        valid = await this.$refs.formStep1.validate()
        let emailValid = await this.checkEmailUnique()
        if (!emailValid)
        {
          valid = false;
          this.showEmail = true
        }
      } else if (this.currentStep == 2) {
        if (this.plan)
        {
          valid = true
        } else {
          valid = await this.$refs.formStep2.validate()
        }
      }
      this.processing = false

      if (!valid)
        return;
      
      if (!this.plan && this.currentStep == 2 && !this.credentials.departments.length)
      {
        Vue.$toast.open({
          message: 'Please select at least one department available.',
          type: 'error',
        })
        return;
      }
      
      if (this.currentStep < this.totalSteps) {
        this.currentStep ++
      } else {
        this.processing = true
        if (this.plan) {
          this.eventbus.$emit('onValidateCard')
        } else {
          this.register();
        }
      }
    },

    register() {
      this.processing = true
      this.credentials.no_trial = this.forceNoTrial

      this.$store.dispatch('auth/businessOrder', this.credentials)
        .then((res) => {
          this.processing = false
          this.showWizard = false

          const business = res.user.business;
          if (this.calendlyUrl && !this.plan && business.properties && business.properties && Array('silver', 'gold').includes(business.properties.level)) {
            this.addCalendarScript()
          } else {
            this.onCalendarSkip();
          }
        })
        .catch((e) => {
          this.processing = false

          if (e.response.data && e.response.data.errors) {
            if (Object.keys(e.response.data.errors).length) {
              this.currentStep = 1
            }

            this.$nextTick(() => {
              this.$refs.formStep1.setErrors(e.response.data.errors)
            })
          }
        })
    },

    onCalendarSkip() {
      this.$router.push({ name: "auth.business.order-thankyou" })
    },

    goToDashboard() {
      this.$router.push({ name: "dashboard" })
    },

    getPlan () {
      this.$store.dispatch('subscription/getPlanByName', { name: this.$route.query.plan, restrict: true })
      .then((res) => {
        this.isLoading = false;
        this.plan = res.data
        this.credentials.plan = this.plan.id
      })
      .catch((err) => {
        window.location.href = '/business/register';
      })
    },

    checkEmailUnique() {
      if (this.credentials.email)
      {
        const params = {
          email: this.credentials.email
        }
        return this.$store.dispatch('auth/checkEmail', params)
        .then((res) => {
          return Promise.resolve(true);
        })
        .catch((e) => {
          if (e.response.data && e.response.data.errors) {
            this.$refs.formStep1.setErrors(e.response.data.errors)
          }
          return Promise.resolve(false);
        })
      } else {
        return Promise.resolve(false);
      }
    },
  },

  computed: {
    isImmeidateCharge() {
      return this.plan && (!this.plan.trial_period_days || this.forceNoTrial)
    },
    
    pageTitle() {
      let title = 'Time to Dive In!';
      if (this.currentStep === 1) {
        title = 'Start Your Free Trial Now'
      }
      return title
    },

    agencyTitle() {
      return this.sitedata && this.sitedata.title
    },

    supportEmail() {
      return this.sitedata && this.sitedata.supportEmail
    },

    supportPhone() {
      return this.sitedata && this.sitedata.supportPhone
    },

    nextButtonLabel() {
      return this.currentStep === this.totalSteps ? 'Submit': 'Next'
    },

    nextButtonClass() {
      return this.currentStep === this.totalSteps ? 'btn-success btn-register': ''
    },

    showPrevious() {
      return this.currentStep != 1
    },

    industriesOptions () {
      return this.$store.state.app.industries
    },

    loading () { 
      return this.$store.state.auth.loading
    },

    sitedata () {
      return this.$store.state.app.sitedata
    },

    calendlyUrl() {
      return this.sitedata && this.sitedata.calendlyUrl
    },
  },
}
</script>

<style lang="scss">
  .business-order {
    width: 840px;
    margin: 0 auto;
    background-color: rgb(242, 242, 242);
    padding: 50px 30px;
    margin-top: 20px;

    .input, .multiselect__tags {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 0 3px rgba(0,0,0,0.05);
      font-size: 16px;
      height: 42px;
    }

    .vue-tel-input {
      border-radius: 5px;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
      border: none;
      height: 46px;
      input {
        height: 100%;
        line-height: 100%;
        border: none;
        margin-top: 0px;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
      .down-container {
        .link {
          margin-top: 2rem;
        }
      }
    }

    h2 {
      text-align: center;
    }

    .link {
      color: #3578c6;
    }

    .btn-next {
      flex: 1 0 auto;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-register {
      font-size: 25px;
      font-weight: 600;
      padding: 14px;
    }

    .prev-link {
      color: #1a76d2;
    }

    .progress-step {
      background: #f5f5f5;
      border-radius: 20px;
      margin-bottom: 40px;
      color: #FFF;
      position: relative;
      box-shadow: 0px 0px 2px #a4a3a3;
      overflow: hidden;
      height: 36px;
      .progress-percent {
        background: #1a76d2;
        text-align: center;
        font-weight: 600;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        line-height: 36px;
      }
    }

    .vuestic-date-picker {
      display: none;
    }

    .skip-wrapper {
      a {
        color: #1a76d2;
      }
    }
    .ready-dive-in {
      p {
        font-size: 18px;
      }
    }
  }
</style>
